import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addToWatchList } from '../../features/watchListSlice'; 
import { Link } from 'react-router-dom';
import imagePlaceHolder from '../../assets/No-Image-Placeholder.png'
import { AgentName } from '../../config/config';
const Card = ({ sugargooAffiliateLink, cnFansAffiliateLink, hagoBuyAffiliateLink, superBuyAffiliateLink, cssBuyAffiliateLink, pandaBuyAffiliateLink, qcLink, itemName, sales, weight, pic_url, dimensions, searchName, itemCategory, price, productId }) => 

{
  const dispatch = useDispatch();
  const watchlist = useSelector((state) => state.watchlist);
  const [isItemInWatchlist, setIsItemInWatchlist] = useState(false);
  const data = { sugargooAffiliateLink, cnFansAffiliateLink, hagoBuyAffiliateLink, superBuyAffiliateLink, cssBuyAffiliateLink, pandaBuyAffiliateLink, qcLink, itemName, sales, weight, pic_url, dimensions, searchName, itemCategory, price, productId };
  const [addedToWatchlist, setAddedToWatchlist] = useState(false);


  const handleWatchListClick = (event) => {
    event.preventDefault();

    // Check if the item is already in the watchlist
    const isInWatchlist = watchlist.some((watchlistItem) => watchlistItem.itemName === data.itemName);

    if (!isInWatchlist) {
      setAddedToWatchlist(true);
      dispatch(addToWatchList(data));
    } else {
      setIsItemInWatchlist(true);
      setAddedToWatchlist(false);
    }

    setTimeout(() => {
      setIsItemInWatchlist(false);
      setAddedToWatchlist(false);
    }, 3000);
  };

  return (
<div className='cardlist flex items-center justify-center flex-col shadow-md p-3 m-2 relative min-h-[830px] max-h[830px]' style={{ width: '350px'  }}>
  <Link className='cardlist w-full min-h-[350px] max-h-[350px] min-w-[350px] max-w-[350px] absolute top-0' 
  to={`/products/${encodeURIComponent(searchName)}/${productId}`} target='_blank' rel='noopener noreferrer'>
    <img className='min-h-[350px] max-h-[350px] min-w-[350px] max-w-[350px]' draggable="false" key={pic_url ? pic_url : imagePlaceHolder} 
    alt={`${AgentName} Spreadsheet` + data.itemName} fetchpriority='high' preload='true' prefetch='true' src={pic_url} /></Link>

    <div className='cardInfo max-sm:mt-48 md:mt-48 relative'>
      <div className='flex justify-end underline'>
      <button
    style={{color: 'var(--text-header-color)', textDecoration: 'var(--text-header-color)'}}
    onClick={handleWatchListClick}
    className={`cardPriceWatchListTitle flex gap-x-2 ${
      addedToWatchlist ? 'text-[#047d1c] decoration-[#047d1c]' :
      isItemInWatchlist ? 'text-red-500 decoration-red-500' :
      'text-[#1E1B18] decoration-[#1E1B18]'
    }`}
  >
    {addedToWatchlist ? 'Product Added to Watchlist!' :
    isItemInWatchlist ? 'Item is already in your watchlist' :
    'Add to Watchlist'}
  </button>

    </div>
    
    <div className=''>
      <p className='cardPriceTitle'>Product Title</p>
      <h2 className='cardTitle text-nowrap overflow-hidden w-[302px] will-change-transform'>{itemName}</h2>
    </div>

    <div className='flex justify-between w-full'>
      <div className='flex flex-col'>
        <p className='cardPriceTitle'>Product Dimensions</p>
        <p className='cardPrice '>{dimensions}</p>
      </div>

      <div className='flex flex-col '>
        <p className='cardPriceTitle'>Product Sales</p>
        <p className='cardPrice '>{sales}</p>
      </div>
    </div>

    <div className='flex justify-between w-full'>
      <div className='flex flex-col'>
        <p className='cardPriceTitle'>Product Weight</p>
        {weight !== "" ? (
          <p className='cardPrice'>{weight} G</p>
        ) : (
          <p className='cardPrice'></p>
        )}
      </div>

      <div className='flex flex-col'>
        <p className='cardPriceTitle'>Product Price</p>
        <p className='cardPrice '>${price}</p>
      </div>
    </div>

    <div className='flex flex-col gap-y-2 items-center w-64 '>
    <a href={cnFansAffiliateLink} target='_blank' rel='noopener noreferrer' className='btn '>Buy at {AgentName}</a>
    <Link to={`/products/${searchName.replace('#', '')}/${productId}`} href={pandaBuyAffiliateLink} target='_blank' rel='noopener noreferrer' className='btn '>Buy via Other Agents</Link>
    <a href={qcLink} target='_blank' rel='noopener noreferrer' className='btn3'>Quality Check</a>

    </div>
  </div>
</div>
  );
}

export default Card;